import axios from "axios";
import { api } from '../api';
import { STATUS_CODE_FORBIDDEN } from "../utils/statusCodeAPI";
import { getURL } from "./commonUrl";
import { loadRefreshToken, loadToken } from "./utils";


axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (Number(error?.response?.data?.statusCode) === STATUS_CODE_FORBIDDEN) {
      api?.auth?.logout(); // Logout user and reload Application
    }
    return Promise.reject(error);
  }
);

class Request {
  constructor() {
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  }

  async get(url, params) {
    const { urlProcess, allParams } = getURL(url, params);
    console.log("urlProcess", urlProcess);
    console.log("param", allParams);
    return await axios.get(urlProcess, {
      params: allParams,
      headers: {
        Authorization: `Bearer ${loadToken()}`
      }
    });
  }

  async getNoHeader(url, params) {
    const { urlProcess, allParams } = getURL(url, params);
    console.log("urlProcess", urlProcess);
    console.log("param", allParams);
    return await axios.get(urlProcess, {
      params: allParams,
    });
  }

  async post(url, formData) {
    const { urlProcess } = getURL(url, formData);
    console.log("urlProcess", urlProcess);
    // let formData = new FormData();
    // Object.keys(params).forEach((key) => {
    //   simplifyParams(formData, key, params[key]);
    // });
    console.log("formData", formData);
    return await axios.post(urlProcess, formData, {
      headers: {
        Authorization: `Bearer ${loadToken()}`
      }
    });
  }

  async postRefreshToken(url, formData) {
    const { urlProcess } = getURL(url, formData);
    console.log("urlProcess", urlProcess);
    // let formData = new FormData();
    // Object.keys(params).forEach((key) => {
    //   simplifyParams(formData, key, params[key]);
    // });
    console.log("formData", formData);
    return await axios.post(urlProcess, formData, {
      headers: {
        Authorization: `Bearer ${loadRefreshToken()}`
      }
    });
  }

  async put(url, formData) {
    const { urlProcess } = getURL(url, formData);
    console.log("formData", formData);
    return await axios.put(urlProcess, formData, {
      headers: {
        Authorization: `Bearer ${loadToken()}`
      }
    });
  }

  async delete(url, params) {
    const { urlProcess, allParams } = getURL(url, params);
    return await axios.delete(urlProcess, {
      params: allParams,
      headers: {
        Authorization: `Bearer ${loadToken()}`
      }
    });
    // .then((response) => {
    //   return response.data;
    // })
    // .catch((error) => {
    //   message.error(error);
    // });
  }

  actionCancel() {
    this.source.cancel("Operation canceled by the user.");
  }
}

export default new Request();
