import { Route, Routes } from 'react-router-dom';
import {
  Welcome,
  About,
  DetailPatient,
  User,
  NotFound,
  MyPatient,
  RecentlyCreatedPatient,
  AdminManageAccount,
  AdminManageHistory,
  IndexAdminManageTool,
  ListPatientFinished
} from '../views';
import AuthRoutes from '../views/Auth';
import { CheckRoleRoute } from './authenticationRoutes/CheckRoleRoute';
import { ROLES } from './authenticationRoutes/rolesCommon';
import { PrivateLayout } from './Layout';

/**
 * List of routes available only for authenticated users
 * Also renders the "Private Layout" composition
 * @component PrivateRoutes
 */
const PrivateRoutes = () => {
  return (
    <PrivateLayout>
      <Routes>
        <Route path="/auth/*" element={<AuthRoutes />} />
        <Route path="/" element={<Welcome />} />
        <Route path="/my-patient/*" element={<MyPatient />} />
        <Route path="/list-patient-finished/*" element={<ListPatientFinished />} />
        <Route path="/recently-created-patient/*" element={<RecentlyCreatedPatient />} />
        <Route path="/about/*"
          element={
            <CheckRoleRoute roles={[ROLES.admin]}>
              <About />
            </CheckRoleRoute>}
        />
        <Route path="/profile-patient/*" element={<DetailPatient />} />
        <Route path="/admin/*"
          element={
            <CheckRoleRoute roles={[ROLES.admin]}>
              <AdminManageAccount />
            </CheckRoleRoute>}
        />
        <Route path="/history-edit-orthodontic-profile/*"
          element={
            <CheckRoleRoute roles={[ROLES.admin]}>
              <AdminManageHistory />
            </CheckRoleRoute>}
        />

        <Route path="/admin/tool-list-management/*"
          element={
            <CheckRoleRoute roles={[ROLES.admin]}>
              <IndexAdminManageTool />
            </CheckRoleRoute>}
        />
        <Route path="/user/*" element={<User />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </PrivateLayout>
  );
};

export default PrivateRoutes;
