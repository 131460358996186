import { useCallback, useEffect, useState } from 'react';
import validate from 'validate.js';

// Same props to style Input, TextField, and so on across the Application
export const SHARED_CONTROL_PROPS = {
  variant: 'outlined',
  margin: 'dense', // 'dense', 'none'
  fullWidth: true,
  size: 'small'
};

// Shared validation rule for Phone numbers
export const VALIDATION_PHONE = {
  type: 'string',
  format: {
    pattern: '^$|[- .+()0-9]+', // Note: We have to allow empty in the pattern
    message: 'should contain numbers',
  },
};

/**
 * Basic object to use as initial value for formState
 * Usage: const [formState, setFormState] = useState(DEFAULT_FORM_STATE);
 */
export const DEFAULT_FORM_STATE = {
  isValid: false, // True when all Input Values are entered correctly
  values: {}, // List of Input Values as string|boolean
  touched: {}, // List of Inputs have been touched as boolean
  errors: {}, // List of Errors for every field as array[] of strings
};

/**
 * Reusable event to cancel the default behavior
 */
export const eventPreventDefault = (event) => {
  event.preventDefault();
};

/**
 * Verifies does the From field with given Name has the Error
 */
export const formHasError = (formState, fieldName) => {
  return Boolean(formState.touched[fieldName] && formState.errors[fieldName]);
};

/**
 * Returns text of "top most" Error for the Form field by given Name.
 * Returns null if there is no Error.
 */
export const formGetError = (formState, fieldName) => {
  return formHasError(formState, fieldName) ? formState.errors[fieldName]?.[0] : null;
};

/**
 * Application "standard" From as Hook
 * Note: the "name" prop of all Form controls must be set! We use event.target?.name for binding data.
 * Usage: const [formState, setFormState, onFieldChange, fieldGetError, fieldHasError] = useAppForm({
    validationSchema: XXX_FORM_SCHEMA,
    initialValues: {name: 'John Doe'},
  });
 * @param {object} options.validationSchema - validation schema in 'validate.js' format
 * @param {object} [options.initialValues] - optional initialization data for formState.values
 */
export function useAppForm({ validationSchema, initialValues = {} }) {
  
  // Validate params
  if (!validationSchema) {
    throw new Error('useAppForm() - the option `validationSchema` is required');
  }
  if (typeof validationSchema !== 'object') {
    throw new Error('useAppForm() - the option `validationSchema` should be an object');
  }
  if (typeof initialValues !== 'object') {
    throw new Error('useAppForm() - the option `initialValues` should be an object');
  }

  // Create Form state and apply initialValues if set
  const [formState, setFormState] = useState({ ...DEFAULT_FORM_STATE, values: initialValues });
 
  // Validation by 'validate.js' on every formState.values change

  useEffect(() => {
    const errors = validate(formState.values, validationSchema,{fullMessages: false})
    setFormState((currentFormState) => ({
      ...currentFormState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [validationSchema, formState.values]);


  // const debounce = (fn, delay) => {
  //   let timerId;
  //   return (...args) => {
  //     clearTimeout(timerId);
  //     timerId = setTimeout(() => fn(...args), delay);
  //   }
  // };
  // Event to call on every Input change. Note: the "name" props of the Input control must be set!

  const onFieldChange =
    useCallback(
      (event, newvalue, nameAutoComplete) => { // chỉ có autocomplete mới có param: nameAutoComplete
        event.persist(); // Todo: Do we need this in React 17 ?
        if (!Array.isArray(newvalue) && !nameAutoComplete) {
          const name = event.target?.name;
          const value =
            event.target?.type === 'checkbox'
              ? event.target?.checked // Checkbox Input
              : event.target?.value; // Any other Input
          if (event.target?.type === 'radio' && event.target?.value === '0' && formState.values.hasOwnProperty(`${name}Note`)) {
            setFormState(
              (formState) => (
                {
                  ...formState,
                  values: {
                    ...formState.values,
                    [name]: value,
                    [`${name}Note`]: ''
                  },
                  touched: {
                    ...formState.touched,
                    [name]: true,
                  },
                }
              )
            )
          } else {
            setFormState(
              (formState) => (
                {
                  ...formState,
                  values: {
                    ...formState.values,
                    [name]: value,
                  },
                  touched: {
                    ...formState.touched,
                    [name]: true,
                  },
                }
              )
            );
          }

        } else { // AutoComplete Input
          const name = nameAutoComplete;
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              [name]: newvalue,
            },
            touched: {
              ...formState.touched,
              [name]: true,
            },
          }));
        }
      }, []);

  // const onFieldChangeDebounce =  debounce(onFieldChange,500)

  // Returns text of "top most" Error for the Field by given Name or null
  const fieldGetError = (fieldName) => formGetError(formState, fieldName);

  // Verifies does the Field with given Name has the Error
  const fieldHasError = (fieldName) => formHasError(formState, fieldName);

  // Return state and methods
  return [formState, setFormState, onFieldChange, fieldGetError, fieldHasError];
}
