import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from '../../components';
import { SideBar } from '../../components/SideBar';
import { TopBar } from '../../components/TopBar';
import { useAppStore } from '../../store';
import { ROLES } from '../authenticationRoutes/rolesCommon';

const TITLE_PRIVATE = '_TITLE_private layout';
const MOBILE_SIDEBAR_ANCHOR = 'left'; // 'right';
const DESKTOP_SIDEBAR_ANCHOR = 'left'; // 'right';
export const SIDEBAR_WIDTH = 240; // 240px

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh', // Full screen height
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  header: {},
  shiftContent: {
    paddingLeft: DESKTOP_SIDEBAR_ANCHOR.includes('left') ? SIDEBAR_WIDTH : 0,
    paddingRight: DESKTOP_SIDEBAR_ANCHOR.includes('right') ? SIDEBAR_WIDTH : 0,
  },
  content: {
    flexGrow: 1, // Takes all possible space
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  footer: {},
}));

/**
 * Centralized place in the App to update document.title
 */
function updateDocumentTitle(title = 'Unknow') {
  if (title) {
    document.title = `${title}`;
  } else {
    document.title = TITLE_PRIVATE;
  }
  return document.title;
}

/**
 * Url to Title mapping
 */
const KNOWN_PAGES = [
  {
    title: 'Page does not exist',
  },
  {
    path: '/auth/signup',
    title: 'Đăng ký',
  },
  {
    path: '/auth/login',
    title: 'Đăng nhập',
  },
  {
    path: '/auth',
    title: 'Authenticate',
  },
  {
    path: '/',
    title: 'Home',
  },
  {
    path: '/welcome',
    title: 'topBar.listOfPatients',
  },
  {
    path: '/my-patient',
    title: 'topBar.myPatients',
  },
  {
    path: '/profile-patient',
    title: 'topBar.orthodonticProfile',
  },
  {
    path: '/user',
    title: 'User',
  },
  {
    path: '/settings',
    title: 'Settings',
  },
  {
    path: '/about',
    title: 'About',
  },
  {
    path: '/recently-created-patient',
    title: 'topBar.recentlyCreatedPatient',
  },
  {
    path: '/admin',
    title: 'topBar.adminManagerAccount',
  },
  {
    path: '/history-edit-orthodontic-profile',
    title: 'Lịch sử chỉnh sửa',
  },
  {
    path: '/Tool list management',
    title: 'Quản lý danh sách khí cụ',
  },
  { 
    path: '/list-patient-finished',
    title: 'List Patient Finished',
  }

];

/**
 * "Link to Page" items in Sidebar
 */
const SIDE_BAR_PRIVATE_ITEMS = [
  {
    title: 'sideBar.patients', //i18 file
    path: '/',
    icon: 'groupsicon',
    roles: null,
  },
  {
    title: 'sideBar.myPatients', //i18 file
    path: '/my-patient',
    icon: 'peopleicon',
    roles: null,
  },
  {
    title: 'sideBar.recentlyCreatedPatient', //i18 file
    path: '/recently-created-patient',
    icon: 'recentactorsicon',
    roles: null,
  },
  {
    title: 'List Patient Finished',
    path: '/list-patient-finished',
    icon: 'howtoregicon',
    roles: null,
  },
  {
    title: 'sideBar.adminManager',
    // path: '/admin', 
    icon: 'manageaccountsicon',
    roles: [ROLES.admin],
    children: [
      {
        title: 'sideBar.admin',
        path: '/admin',
        icon: 'fibermanualrecordicon',
        roles: [ROLES.admin],
      },
      // {
      //   title: 'sideBar.historyEditOrthodonticProfile',
      //   path: '/history-edit-orthodontic-profile',
      //   icon: 'fibermanualrecordicon',
      //   roles: [ROLES.admin],
      // },
      // {
      //   title: 'Tool List Management',
      //   path: '/admin/tool-list-management',
      //   icon: 'fibermanualrecordicon',
      //   roles: null,
      // },

    ]
  },
  // {
  //   title: 'Profile',
  //   path: '/profile',
  //   icon: 'tools',
  // },
  {
    title: 'sideBar.aboutSystem',
    path: undefined,
    icon: 'settingssuggesticon',
    roles: [ROLES.admin],
    children: [
      {
        title: 'sideBar.about',
        path: '/about',
        icon: 'fibermanualrecordicon',
        roles: [ROLES.admin],
      }
    ]
  },

];

/**
 * Renders "Private Layout" composition
 * @component PrivateLayout
 */
const PrivateLayout = ({ children }) => {
  const [state] = useAppStore()
  const [openSideBar, setOpenSideBar] = useState(false)
  const [title, setTitle] = useState()
  const theme = useTheme()
  const classes = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), { defaultMatches: true })
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n, ready } = useTranslation()

  useEffect(() => {
    let newTitle = ''
    if (location?.pathname !== '/') {
      const matchingPages = KNOWN_PAGES.filter(
        (page) =>
          page?.path && // Ignore empty
          page?.path?.length > 1 && // Ignore '/'
          location?.pathname.includes(page?.path)
      );
      matchingPages.push(KNOWN_PAGES[0]) // "NotFound" page as a fallback
      newTitle = matchingPages[0]?.title
      updateDocumentTitle(t(newTitle))
    } else {
      newTitle = 'topBar.listOfPatients'
      updateDocumentTitle(t(newTitle)) // Reset to default App title
    }
    setTitle(t(newTitle))
  })

  const handleLogoClick = useCallback(() => {
    // Navigate to '/' when clicking on Logo/Menu icon when the SideBar is already visible
    navigate('/')
  }, [navigate])

  const handleSideBarOpen = useCallback(() => {
    if (!openSideBar) setOpenSideBar(true)
  }, [openSideBar])

  const handleSideBarClose = useCallback(() => {
    if (openSideBar) setOpenSideBar(false)
  }, [openSideBar])

  const classRoot = clsx({
    [classes.root]: true,
    [classes.shiftContent]: isDesktop,
  });
  const shouldOpenSideBar = isDesktop ? true : openSideBar
  if (ready) {
    return (
      <Grid container direction="column" className={classRoot}>
        <Grid item className={classes.header} component="header">
          <TopBar
            isAuthenticated={state.isAuthenticated}
            title={title}
            onMenu={shouldOpenSideBar ? handleLogoClick : handleSideBarOpen}
          />

          <SideBar
            anchor={isDesktop ? DESKTOP_SIDEBAR_ANCHOR : MOBILE_SIDEBAR_ANCHOR}
            open={shouldOpenSideBar}
            variant={isDesktop ? 'persistent' : 'temporary'}
            items={SIDE_BAR_PRIVATE_ITEMS}
            onClose={handleSideBarClose}
          />
        </Grid>

        <Grid className={classes.content} component="main">
          <Box sx={{ width: '100%', padding: 2, borderRadius: 2, border: '1px solid #81d784', marginBottom: 1 }}>
            <ErrorBoundary name="Content">{children}</ErrorBoundary>
          </Box>
        </Grid>

      </Grid>
    )
  } else {
    return <p>loading</p>
  }

};

export default PrivateLayout;
