import { LoadingButton } from '@mui/lab';
import {
  Card, CardContent, CardHeader, Checkbox,
  FormControlLabel, Grid, InputAdornment,
  LinearProgress, TextField, Tooltip
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUser } from '../../../apis/apiEnpoint';
import Request from '../../../apis/requestAxios';
import { clearAuthData } from '../../../apis/utils';
import { AppIcon, AppIconButton } from '../../../components';
import { AppAlert, AppForm } from '../../../components/forms';
import { useAppStore } from '../../../store';
import { eventPreventDefault, SHARED_CONTROL_PROPS, useAppForm } from '../../../utils/form';

const VALIDATE_FORM_SIGNUP = {
  email: {
    email: true,
    presence: true,
  },
  phone: {
    type: 'string',
    format: {
      pattern: '^$|[- .+()0-9]+', // Note: We have to allow empty in the pattern
      message: 'should contain numbers',
    },
  },
  // name: {
  //   type: 'string',
  //   presence: { allowEmpty: false },
  //   format: {
  //     pattern: '^[A-Za-z ]+$', // Note: Allow only alphabets and space
  //     message: 'should contain only alphabets',
  //   },
  // },

  password: {
    presence: true,
    format: {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/ ,
      message: "Must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character (!, @, #, $, %, ^, &, *)"
    }
  },
};

const VALIDATE_EXTENSION = {
  confirmPassword: {
    equality: 'password',
  },
};

/**
 * Renders "Signup" view
 * url: /auth/signup
 */
const SignupView = () => {
  clearAuthData();
  const navigate = useNavigate();
  const [, dispatch] = useAppStore();
  const [validationSchema, setValidationSchema] = useState({
    ...VALIDATE_FORM_SIGNUP,
    ...VALIDATE_EXTENSION,
  });
  const [formState, /* setFormState */, onFieldChange, fieldGetError, fieldHasError] = useAppForm({
    validationSchema: validationSchema, // the state value, so could be changed in time
    initialValues: {
      name: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [agree, setAgree] = useState(false);
  const [agreeReciveMail, setAgreeReciveMail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const values = formState.values; // Typed alias to formState.values as the "Source of Truth"

  //snack bar start
  const { enqueueSnackbar /*, closeSnackbar*/ } = useSnackbar();
  const showSnackBar = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
    });
  };
  //snack bar end

  useEffect(() => {
    // Component Mount
    let componentMounted = true;

    async function fetchData() {
      //TODO: Call any Async API here
      if (!componentMounted) return; // Component was unmounted during the API call
      //TODO: Verify API call here

      setLoading(false); // Reset "Loading..." indicator
    }
    fetchData(); // Call API asynchronously

    return () => {
      // Component Un-mount
      componentMounted = false;
    };
  }, []);

  useEffect(() => {
    // Update Validation Schema when Show/Hide password changed
    let newSchema;
    if (showPassword) {
      newSchema = VALIDATE_FORM_SIGNUP; // Validation without .confirmPassword
    } else {
      newSchema = { ...VALIDATE_FORM_SIGNUP, ...VALIDATE_EXTENSION }; // Full validation
    }
    setValidationSchema(newSchema);
  }, [showPassword]);

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  const handleAgreeClick = useCallback(() => {
    setAgree((oldValue) => !oldValue);
  }, []);

  const handleAgreeReceiveClick = useCallback(() => {
    setAgreeReciveMail((oldValue) => !oldValue);
  }, []);

  const handleFormSubmit = useCallback(
    async (event) => {
      setLoadingSubmit(true)
      event.preventDefault();
      await Request.post(apiUser.singin, values)
        .then((res) => {
          setLoadingSubmit(false)
          navigate('/auth/login');
          showSnackBar(res.data.message, 'success')

        })
        .catch((err) => {
          console.log("errrrrrrrrrrrrrrrrr: ", err);
          console.log("err.response.data.message: ", err.response.data.message);
          err.response.data ?
            showSnackBar(err.response.data.message, 'error') :
            showSnackBar(err.message, 'error')
          setLoadingSubmit(false)

        });
      // const apiResult = await api?.auth?.signup(values);

      // if (!apiResult) {
      //   setError('Can not create user for given email, if you already have account please sign in');
      //   return; // Unsuccessful signup
      // }

      // dispatch({ type: 'SIGN_UP' });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, values, navigate]
  );

  const handleCloseError = useCallback(() => setError(undefined), []);

  if (loading) return <LinearProgress />;

  return (
    <AppForm onSubmit={handleFormSubmit}>
      <Grid container spacing={2} style={{ background: "linear-gradient(-135deg,#50c854,#4195d0)", height: "87vh" }}>

        <Grid item xs={12} md={4} lg={4}></Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Card >
            <CardHeader title="Sign Up" />
            <CardContent>
              <TextField
                required
                label="Email"
                name="email"
                value={values.email}
                error={fieldHasError('email')}
                helperText={fieldGetError('email') || ' '}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
              />
              <TextField
                required
                label="Phone"
                name="phone"
                value={values.phone}
                error={fieldHasError('phone')}
                helperText={fieldGetError('phone') || ' '}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
              />
              <TextField
                required
                label="Full Name"
                name="name"
                value={values.name}
                error={fieldHasError('name')}
                helperText={fieldGetError('name') || ' '}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
              />

              <TextField
                required
                type={showPassword ? 'text' : 'password'}
                label="Password"
                name="password"
                value={values.password}
                error={fieldHasError('password')}
                helperText={fieldGetError('password') || ' '}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AppIconButton
                        aria-label="toggle password visibility"
                        icon={showPassword ? 'visibilityon' : 'visibilityoff'}
                        title={showPassword ? 'Hide Password' : 'Show Password'}
                        onClick={handleShowPasswordClick}
                        onMouseDown={eventPreventDefault}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {!showPassword && (
                <TextField
                  required
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  error={fieldHasError('confirmPassword')}
                  helperText={fieldGetError('confirmPassword') || ' '}
                  onChange={onFieldChange}
                  {...SHARED_CONTROL_PROPS}
                />
              )}
              <FormControlLabel
                control={<Checkbox required name="agree" checked={agree} onChange={handleAgreeClick} />}
                label="Tôi đồng ý với điều khoản tại nha khoa Thùy Anh."
              />

              <FormControlLabel
                control={<Checkbox required name="agree" checked={agreeReciveMail} onChange={handleAgreeReceiveClick} />}
                label="Tôi đồng ý nhận thông tin tài khoản và mật khẩu qua mail tôi đã nhập."
              />

              {error ? (
                <AppAlert severity="error" onClose={handleCloseError}>
                  {error}
                </AppAlert>
              ) : null}

              <Grid container justifyContent="center" alignItems="center">
                {/* <AppButton type="submit" disabled={!(formState.isValid && agree)}>
              Confirm and Sign Up
            </AppButton> */}
                <Tooltip title="Đăng ký tài khoản">
                  <span>
                    <LoadingButton
                      size="small"
                      color="primary"
                      onClick={handleFormSubmit}
                      loading={loadingSubmit}
                      loadingPosition="start"
                      startIcon={<AppIcon icon="signup" type="submit" title="signup" />}
                      variant="contained"
                      disabled={!(formState.isValid && agree && agreeReciveMail)}
                    >
                      Đồng ý và đăng ký
                    </LoadingButton>
                  </span>
                </Tooltip>
              </Grid>
            </CardContent>
          </Card></Grid>
        <Grid item xs={12} md={4} lg={4}></Grid>
      </Grid>
    </AppForm>
  );
};

export default SignupView;
