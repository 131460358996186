import { ErrorBoundary } from './components';
import { AppRoutes } from './routes';
import { AppStore } from './store';
import { AppThemeProvider } from './theme';
import { AppSnackBarProvider } from './components/AppSnackBar';
import IdleTimer from './components/IdleTimer';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/**
 * Root Application Component
 * @component App
 */
const App = () => {
  return (
    <ErrorBoundary name="App">
      <AppStore>
        <IdleTimer />
        <AppThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppSnackBarProvider>
            <AppRoutes />
          </AppSnackBarProvider>
          </LocalizationProvider>
        </AppThemeProvider>
      </AppStore>
    </ErrorBoundary>
  );
};

export default App;
