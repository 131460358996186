import { AppBar, Avatar, FormControl, InputLabel, MenuItem, Select, Toolbar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { saveI18Lng } from '../../apis/utils'
import { useAppStore } from '../../store'
import AppIconButton from '../AppIconButton'

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: 'none',
    minWidth: '20rem',
    // backgroundColor: '#fff0'
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  logo: {
    height: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  buttons: {},
}))

/**
 * Renders TopBar composition
 * @component TopBar
 */
const TopBar = ({ className, title = '', isAuthenticated, onMenu, onNotifications, ...restOfProps }) => {
  
  const classes = useStyles()
  const iconMenu = false ? 'account' : 'menu'
  const [state, dispatch] = useAppStore()
  console.log("stateeeeeeeeeeeeeeeee: ",state)

  const [language, setLanguage] = useState(state.i18Lng?? 'vi')

  const { t, i18n } = useTranslation()

  const changeLanguage = (e) => {
    setLanguage(e.target.value)
    saveI18Lng(e.target.value)
    dispatch({ type: 'SET_LANGUAGE', language: e.target.value})
    i18n.changeLanguage(e.target.value)
  }

  // const handleChange = (event) => {
  //   setLanguage(event.target.value)
  // }

  return (
    <AppBar {...restOfProps} className={clsx(classes.root, className)} component="div" color="inherit" >
      <Toolbar className={classes.toolbar} disableGutters>
        {/* <AppIconButton
          icon="logo"
          // color="primary"
          onClick={onMenu}
        /> */}
        <Avatar src={require('./avatar.png')} alt="avatar" sx={{ ml: 2 }} onClick={onMenu} />
        <Typography variant="h6" className={classes.title}>
          {t(title)}
        </Typography>
        <div className={classes.buttons}>
          
          <FormControl fullWidth = {false} variant="standard" size={"small"} margin ={'dense'}>
            <InputLabel id="demo-simple-select-label">{t('topBar.language')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label={t('topBar.language')}
              onChange={changeLanguage}
            >
              <MenuItem value={'vi'}>{t('topBar.vietnames')}</MenuItem>
              <MenuItem value={'en'}>{t('topBar.english')}</MenuItem>
              
            </Select>
          </FormControl>
          {isAuthenticated && (
            <AppIconButton icon="notifications" color="inherit" title="User Notifications" onClick={onNotifications} />
          )}
          {/* <AppIconButton icon={iconMenu} color="inherit" title="Open Menu" onClick={onMenu} /> */}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
