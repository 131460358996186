import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button, Collapse, List, ListItem, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { checkRole } from "../../routes/authenticationRoutes/rolesCommon";
import { useAppStore } from "../../store";
import AppIcon from "../AppIcon";
import SideBarLink from './SideBarLink';

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0
    },
    button: {
        // color: theme.palette.button,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        // fontWeight: theme.typography.fontWeightMedium,
        flexGrow: 1,
    },
    buttonNoPath:{
        color: theme.secondary
    },
    iconOrMargin: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
}));
export const ListItemLink = (props) => {
    const { title, path, icon, roles, children, showIcons, ...other } = props
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [state, dispatch] = useAppStore();
    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    }
    let iconArrow = null
    if (children) {
        iconArrow = open ? <ExpandLess onClick={children ? handleClick : null} /> : <ExpandMore onClick={children ? handleClick : null} />
    }

    return (
        <>
            {state?.currentUser && checkRole(state?.currentUser?.roles, roles) &&
                <>
                    <ListItem {...other}>
                        {path ?
                            <Button className={classes.button} component={SideBarLink} to={path ? path : null}
                                onClick={children ? handleClick : null}>
                                <div className={classes.iconOrMargin}>{showIcons && icon ? <AppIcon icon={icon} fontSize={'small'} /> : null}</div>
                                {t(title)}
                            </Button>
                            :
                            <Button className={classes.button} color={'inherit'}
                                onClick={children ? handleClick : null}>
                                <div className={clsx(classes.iconOrMargin) }>{showIcons && icon ? <AppIcon icon={icon} fontSize={'small'} /> : null}</div>
                                {t(title)}
                            </Button>
                        }
                        {iconArrow}
                    </ListItem>
                    {children &&
                        children.map((children) =>
                            <Collapse component="li" in={open} timeout="auto" unmountOnExit key={`${children?.title}`}>
                                <List disablePadding>
                                    <ListItemLink sx={{ pl: 2 }}
                                        showIcons={true}
                                        key={`${children?.title}`}
                                        title={children?.title}
                                        path={children?.path}
                                        icon={children?.icon}
                                        roles={children?.roles}
                                    />
                                </List>
                            </Collapse>
                        )
                    }
                </>
            }
        </>
    );
}