import { saveI18Lng } from '../api/auth/utils';
import { saveRefreshToken, saveToken } from '../apis/utils';
import { localStorageSet } from '../utils/localStorage';

/**
 * Main reducer for global AppStore using "Redux styled" actions
 * @function AppReducer
 * @param {object} state - current/default state
 * @param {string} action.type - unique name of the action
 * @param {*} [action.payload] - optional data object or the function to get data object
 */
const AppReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      const currentUser = action?.currentUser
      localStorageSet('me', action?.currentUser);
      return {
        ...state,
        currentUser
        // currentUser: action?.currentUser || action?.payload,
      };
    case 'SIGN_UP':
    case 'LOG_IN':
      saveToken(action?.token)
      saveRefreshToken(action?.refreshToken)
      saveI18Lng('en')
      return {
        ...state,
        isAuthenticated: true,

      };
    case 'LOG_OUT':
      return {
        ...state,
        isAuthenticated: false,
        currentUser: undefined, // Also reset previous user data
      };
    case 'SET_DARK_MODE': {
      const darkMode = action?.darkMode ?? action?.payload;
      localStorageSet('darkMode', darkMode);
      return {
        ...state,
        darkMode,
      };
    }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        i18Lng: action?.language
      };
    case 'SET_PAGE_WELLCOME':
      return {
        ...state,
        pageWellcome: action?.value
      };
    case 'SET_PAGE_RECENTLY_CREATE_PATIENT':
      return {
        ...state,
        pageRecentlyCreatedPatient: action?.value
      };
    case 'SET_PAGE_MY_PATIENT':
      return {
        ...state,
        pageMyPatient: action?.value
      };
    case 'SET_PAGE_PATIENT_FINISHED':
      return {
        ...state,
        pageMyPatient: action?.value
      };
    case 'CHANGE_ROW_PER_PAGE': {
      localStorageSet('rowsPerPage', action?.value)
      return {
        ...state,
        rowsPerPage: action?.value
      }
    }
    case 'SET_COLLAPSE': {
      localStorageSet('isCollapse', action?.value)
      return {
        ...state,
        isCollapse: action?.value
      }
    }

    default:
      return state;
  }
};

export default AppReducer;
