/**
 * Material UI theme
 * See for details: https://material-ui.com/customization/default-theme/?expand-path=$.palette
 * Martial Color tool: https://material.io/resources/color
 */

import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { enUS, viVN } from '@mui/material/locale';
import { useAppStore } from './store/AppStore';

/**
 * Material UI theme "front" colors, "back" colors are different for Light and Dark modes
 */
const FRONT_COLORS = {
  primary: {
    main: '#81d784', // Green 300
    contrastText: '#000000',
  },
  secondary: {
    main: '#ffb74d', // Orange 300
    contrastText: '#000',
  },
  info: {
    main: '#0277bd', // Light Blue 800
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#2e7d32', // Green 800
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#f9a825', // Yellow 800
    // contrastText: '#000000',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#c62828', // Red 800
    contrastText: '#FFFFFF',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
};

/**
 * Material UI theme config for "Light Mode"
 */
const LIGHT_THEME = {
  palette: {
    mode: 'light',
    background: {
      paper: '#f5f5f5', // Gray 100 - Background of "Paper" based component
      default: '#FFFFFF',
      // paper: '#FFFFFF', // Gray 100 - Background of "Paper" based component
      // default: '#f5f5f5',
      selected: '#cbcbcb'
    },
    action: {
      // hover: '#b9f6ca91',
      active: '#387002'
    },
    ...FRONT_COLORS,
  },
};

/**
 * Material UI theme config for "Dark Mode"
 */
const DARK_THEME = {
  palette: {
    mode: 'dark',
    background: {
      paper: '#212b36',   // Gray 800 - Background of "Paper" based component
      default: '#161c24',
      // paper: '#424242',  // Gray 800 - Background of "Paper" based component
      // default: '#303030',  
      selected: '#6b6b6b'
    },
    // action: {hover: '#b9f6ca91'},
    ...FRONT_COLORS,
  },

};

/**
 * Material UI Provider with Light and Dark themes depending on global "state.darkMode"
 */
const AppThemeProvider = ({ children }) => {
  const [state] = useAppStore();
  const theme =  (state.darkMode ? createTheme(DARK_THEME, state.i18Lng==='en'? enUS : viVN) 
  : createTheme(LIGHT_THEME, state.i18Lng==='en'? enUS : viVN));
  // const theme = state.darkMode ? createTheme(DARK_THEME) : createTheme(LIGHT_THEME);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline /* Material UI Styles */ />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export { AppThemeProvider, LIGHT_THEME, DARK_THEME };

