import { Card, CardContent, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { apiUser } from '../../../apis/apiEnpoint';
import Request from '../../../apis/requestAxios';
import { setRefreshTimeout } from '../../../apis/utils';
import { AppButton, AppIconButton, AppLink } from '../../../components';
import { AppAlert, AppForm } from '../../../components/forms';
import { useAppStore } from '../../../store';
import { eventPreventDefault, SHARED_CONTROL_PROPS, useAppForm } from '../../../utils/form';

const VALIDATE_FORM_LOGIN_EMAIL = {
  username: {
    presence: true,
    // email: true,
  },
  password: {
    presence: true,
    // format: {
    //   // eslint-disable-next-line no-useless-escape
    //   pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/ ,
    //   message: "Must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character (!, @, #, $, %, ^, &, *)"
    // }
  },
};

/**
 * Renders "Login with Email" view for Login flow
 * url: /auth/login/email/*
 */
const LoginEmailView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [, dispatch] = useAppStore();
  const [formState, , /* setFormState */ onFieldChange, fieldGetError, fieldHasError] = useAppForm({
    validationSchema: VALIDATE_FORM_LOGIN_EMAIL,
    initialValues: { username: '', password: '' },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();
  const values = formState.values; // Typed alias to formState.values as the "Source of Truth"

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  //snack bar start
  const { enqueueSnackbar /*, closeSnackbar*/ } = useSnackbar();
  const showSnackBar = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
    });
  };
  //snack bar end

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      // const result = await api?.auth?.login(values);
      await Request.post(apiUser.login, values)
        .then((res) => {
          dispatch({ type: 'LOG_IN', token: res.data.data.accessToken, expire: res.data.data.expire, refreshToken: res.data.data.refreshToken })
          dispatch({ type: 'SET_CURRENT_USER', currentUser: res.data.data.me })
          // setRefreshTimeout(parseInt(res.data.data.expire)/10000 +10000); //debug 0.8 minutes
          setRefreshTimeout(parseInt(res.data.data.expire));
          navigate('/', { state: { name: 'nghiatq', age: 18 } });
        })
        .catch((err) => {
          console.log("errrrrrrrrrrrrrrrrr: ", err);
          err.response.data ?
            showSnackBar(err.response.data.message, 'error') :
            showSnackBar(err.message, 'error')
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, dispatch, navigate]
  );

  const handleCloseError = useCallback(() => setError(undefined), []);

  return (
    <AppForm onSubmit={handleFormSubmit}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ background: "linear-gradient(-135deg,#50c854,#4195d0)", height: "87vh" }}

      >
        <Grid item xs={12} sx={{ maxWidth: { xs: 400, lg: 475 } }}>
          <Card>
            {/* <CardHeader
              title="Member Login "
              SX={{ color: theme.palette.primary.main }}
            /> */}
            <CardContent>
              <Typography
                color={theme.palette.primary.main}
                gutterBottom
                variant={'h5'}
              >
                Hi, Welcome Back
              </Typography>
              <TextField
                required
                label="Username"
                name="username"
                value={values.username}
                error={fieldHasError('username')}
                helperText={fieldGetError('username') || ' '}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
              />
              <TextField
                required
                type={showPassword ? 'text' : 'password'}
                label="Password"
                name="password"
                value={values.password}
                error={fieldHasError('password')}
                helperText={fieldGetError('password') || ' '}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AppIconButton
                        aria-label="toggle password visibility"
                        icon={showPassword ? 'visibilityon' : 'visibilityoff'}
                        title={showPassword ? 'Hide Password' : 'Show Password'}
                        onClick={handleShowPasswordClick}
                        onMouseDown={eventPreventDefault}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {error ? (
                <AppAlert severity="error" onClose={handleCloseError}>
                  {error}
                </AppAlert>
              ) : null}
              <Grid container justifyContent="center" alignItems="center">
                <AppButton type="submit" disabled={!formState.isValid}>
                  Login
                </AppButton>
                <AppButton variant="text" component={AppLink} to="/auth/recovery/password">
                  Forgot Password
                </AppButton>
              </Grid>
              <Divider />
              <Typography
                component={Link}
                to="/auth/signup"
                variant="subtitle1"
                sx={{ textDecoration: 'none', color: 'black' }}
              >
                Don&apos;t have an account?
              </Typography>
            </CardContent>
          </Card>
        </Grid>


      </Grid>
    </AppForm>
  );
};

export default LoginEmailView;
