
export const getURL = (url, params) => {
  console.log('>>>>>>>>>>>>>>>>>>>>>>>',process.env.REACT_APP_ENDPOINT);
    let localUrl = process.env.REACT_APP_ENDPOINT + url;
    
    let localParams = params;
    localParams = localParams || {};
    // if (!localUrl.startsWith("http") && !localUrl.startsWith("https")) {
    //     localUrl =
    //     ENDPOINT +
    //       (localUrl.startsWith("/") ? localUrl : `/${localUrl}`);
    // }
    // const myHeaders = new Headers();
    // Object.keys(localHeaders).forEach((key) => {
    //   myHeaders.append(key, localHeaders[key]);
    // });
    return {
      urlProcess: localUrl,
      allParams: localParams,
      // headersData: myHeaders,
    };
  };
  
  export const createFrom = (params, form) => {
    let localForm = form;
    if (!localForm) {
      localForm = new FormData();
    }
    if (!params) {
      return localForm;
    }
    Object.keys(params).forEach((key) => {
      this.simplifyParams(localForm, key, params[key]);
    });
    return localForm;
  };
  
  export const simplifyParams = (form, key, param) => {
    if (typeof param === "undefined" || param === null) return;
  
    if (typeof param !== "object" || param instanceof File) {
      form.append(key, param);
      return;
    }
  
    if (Array.isArray(param)) {
      param.forEach((value, i) => {
        this.simplifyParams(form, `${key}[${i}]`, value);
      });
    } else {
      Object.keys(param).forEach((subKey) => {
        this.simplifyParams(form, `${key}[${subKey}]`, param[subKey]);
      });
    }
  };
  
