import { apiUser } from '../../apis/apiEnpoint';
import Request from '../../apis/requestAxios';
import { saveRefreshToken, saveToken, setRefreshTimeout } from '../../apis/utils';
import api from '../api';

export const refreshByAxios = async () => {

  await Request.postRefreshToken(apiUser.refreshToken)
    .then((res) => {
      saveToken(res.data.data.accessToken);
      saveRefreshToken(res.data.data.refreshToken);
      // setRefreshTimeout(parseInt(res.data.data.expire) / 10000 + 10000); //debug 0.8 minutes
      setRefreshTimeout(parseInt(res.data.data.expire));
    })
    .catch((err) => {
      api.auth.logout(); // Logout user and reload Application
    });

  // const payload = {
  //   refresh_token: loadRefreshToken(),
  // };
  // try {
  //   const res = process.env.REACT_APP_MULTIPASS ? fakeApiResponse() : await api.axios.post(ENDPOINT, payload);
  //   const { data } = res;
  //   log.warn(`${METHOD} -`, data);

  //   saveToken(data?.access_token);
  //   saveRefreshToken(data?.refresh_token);
  //   setRefreshTimeout(data?.expires);
  //   log.warn(METHOD, '- token expires in', +data?.expires / 1000 / 60, 'minutes');

  //   return data;
  // } catch (error) {
  //   log.error(`${METHOD} -`, error);
  //   api.auth.logout(); // Logout user and reload Application
  // }
  // return undefined;
}

// export default refreshByAxios;
