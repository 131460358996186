import { List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListItemLink } from './ListItemLink';
import { PropTypeSideBarItems } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  button: {
    // color: theme.palette.button,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    // fontWeight: theme.typography.fontWeightMedium,
    flexGrow: 1,
  },
  iconOrMargin: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
}));

/**
 * Renders list of Navigation Items inside SideBar
 * @component SideBarNavigation
 * @param {string} [prop.className] - optional className for styling
 * @param {array} props.items - list of objects to render as navigation links
 * @param {boolean} [props.showIcons] - icons in links are visible when true
 * @param {func} [props.afterLinkClink] - optional callback called when some link was clicked
 */
const SideBarNavigation = ({ className, items, showIcons = false, afterLinkClick, ...restOfProps }) => {
  const classes = useStyles();
  const classRoot = clsx(classes.root, className);

  return (
    <nav>
      <List className={classRoot} {...restOfProps}>
        {/* {items.map((link) => (
          <>
            <ListItem key={`${link.title}-${link.href}`} className={classes.item} disableGutters>
              {state?.currentUser && checkRole(state?.currentUser?.roles, link.roles) &&
                <Button className={classes.button} component={SideBarLink} to={link.path} onClick={afterLinkClick}>
                  <div className={classes.iconOrMargin}>{showIcons && link.icon ? <AppIcon icon={link.icon} fontSize={'small'} /> : null}</div>
                  {t(link.title)}
                </Button>
              }
            </ListItem>

          </>

        ))} */}
        {items.map((item) => (
            <ListItemLink className={classes.item}
              showIcons
              key={`${item?.title}`}
              title={item.title}
              path={item?.path}
              icon={item?.icon}
              roles={item?.roles}
              children={item?.children}
            />
        ))}
      </List>
    </nav>
  );
};

SideBarNavigation.propTypes = {
  className: PropTypes.string,
  items: PropTypeSideBarItems.isRequired,
  showIcons: PropTypes.bool,
  afterLinkClink: PropTypes.func,
};

export default SideBarNavigation;
