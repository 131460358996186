import { useCallback, useState } from 'react';
import { Grid, TextField, Card, CardHeader, CardContent } from '@mui/material';
import { AppButton } from '../../../components';
import { AppForm, AppAlert } from '../../../components/forms';
import { useAppForm, SHARED_CONTROL_PROPS } from '../../../utils/form';
// import { api } from '../../../api';
import { apiUser } from '../../../apis/apiEnpoint';
import Request from '../../../apis/requestAxios';
import { useSnackbar } from 'notistack';
import { STATUS_CODE_SUCCESS } from '../../../utils/statusCodeAPI';

const VALIDATE_FORM_RECOVERY_PASSWORD = {
  email: {
    presence: true,
    email: true,
  },
};

/**
 * Renders "Recover Password" view for Login flow
 * url: /uth/recovery/password
 * @param {string} [props.email] - pre-populated email in case the user already enters it
 */
const RecoveryPasswordView = ({ email = '' }) => {
  const [formState, , /* setFormState */ onFieldChange, fieldGetError, fieldHasError] = useAppForm({
    validationSchema: VALIDATE_FORM_RECOVERY_PASSWORD,
    initialValues: { email },
  });
  const [message, setMessage] = useState();
  const [isSentMailSuccess,setIsSentMailSuccess] = useState();
  const values = formState.values; // Typed alias to formState.values as the "Source of Truth"

  //snack bar start

  const { enqueueSnackbar /*, closeSnackbar*/ } = useSnackbar();
  const showSnackBar = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
    });
  };
  //snack bar end

  const resetPassword = async (email) => {
    let response = null
    await Request.post(apiUser.resetPassword, {
      accInfo:email
    })
      .then((res) => {
        response = res
        showSnackBar(res.data.message, 'success')
      })
      .catch((err) => {
        console.log("errrrrrrrrrrrrrrrrr: ", err);
        err.response.data ?
          showSnackBar(err.response.data.message, 'error') :
          showSnackBar(err.message, 'error')
        return
      });
    return response
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // await api?.auth?.recover(values);
    let res = await resetPassword(values.email)

    //Show message with instructions for the user
    if(res?.data?.code === STATUS_CODE_SUCCESS){
      setIsSentMailSuccess(true)
      setMessage('Email with instructions has been sent to your address')
    }else{
      setIsSentMailSuccess(false)
      setMessage('Sorry we cant not sent to your address')
    }
  };

  const handleCloseError = useCallback(() => setMessage(undefined), []);

  return (
    <AppForm onSubmit={handleFormSubmit}>
      <Card>
        <CardHeader title="Recover Password" />
        <CardContent>
          <TextField
            required
            label="Email"
            name="email"
            value={values.email}
            error={fieldHasError('email')}
            helperText={fieldGetError('email') || ' '}
            onChange={onFieldChange}
            {...SHARED_CONTROL_PROPS}
          />

          {message ? (
            <AppAlert severity={isSentMailSuccess? "success": "warning" } onClose={handleCloseError}>
              {message}
            </AppAlert>
          ) : null}

          <Grid container justifyContent="center" alignItems="center">
            <AppButton type="submit" disabled={!formState.isValid}>
              Send Password Recovery Email
            </AppButton>
          </Grid>
        </CardContent>
      </Card>
    </AppForm>
  );
};

export default RecoveryPasswordView;
