export const apiUser = {
  login: '/auth/login',
  resetPassword: '/auth/reset-password',
  changePassword: '/auth/change-password',
  singin: '/doctor/create-doctor',
  getAllDoctor: '/doctor/list-doctor', //man admin
  getAllDoctorDropdown: '/doctor/list-doctor-dropdown',
  editStautsIsActive: '/doctor/active-doctor/',
  getInfoDoctor: '/doctor/infor-doctor/',
  refreshToken: '/auth/refresh-token',
  getAllDoctorAndAdmin: "/doctor/list-doctor-and-admin"
};

export const apiPatient = {
  getListPatient: '/patient/list-all-patient',
  getListPatientOfMe: '/patient/list-patient-of-doctor',
  getDetail: '/patient',
  getListPatientInMonth: '/patient/list-all-recent-patient',
  createPatient: '/patient/create-patient',
  updateDiagnosticPlan: '/patient/create-diagnostic-plan',
  updateInfoPatient: '/patient/',
  updateType: '/patient/update-type/',
  updateGeneralExamination: '/patient/create-examination/',
  delete: '/patient/',
  updateDateStartWork: '/patient/create-date-start-work/'
}

export const apiDentalAppliance = {
  createDentalAppliance: '/tool/create-patient-tool/',
  editDentalAppliance: '/tool/',
  deleteDentalAppliance: '/tool/'
}

export const apiProcess = {
  createProcess: '/patient-process/create/',
  editProcess: '/patient-process/',
  deleteProcess: '/patient-process/'
}

export const apiImages = {
  uploadImages: '/image/upload',
  deleteImage: '/image/',
  getImagesByType: '/image/image-by-type',
  getListImagesByTypeOfProcess: '/patient-process/images-type-of-process',
  getListTypeOfImagesByProcess: '/patient-process/list-images-of-process/',
  noteImage:'/image/note/',
  getALlImagesOfPatient: '/image/list-images-of-patient/'
}

export const apiClinicAddress = {
  getAllClinicAddress:'/clinic-address/list-clinic-address'
}

export const apiLogs = {
  getLogsByIdPatient: '/log/list-log-patient',
  getlistLogDetailOfEachDay:'/log/list-log-detail-of-each-day'
}