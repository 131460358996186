import { Navigate } from 'react-router-dom';
import { useAppStore } from '../../store';
import PageAuthorization from '../../views/404';
import { checkRole } from './rolesCommon';

export const CheckRoleRoute = (props) => {
  const { children, roles } = props;
  const [state, dispatch] = useAppStore();

  const user = { ...state.currentUser }

  let userHasRequiredRole = checkRole(user.roles, roles)
  if (!state.isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  if (state.isAuthenticated && !userHasRequiredRole) {
    return <PageAuthorization />; // build your won access denied page (sth like 404)
  }
  return children;
}
// export default CheckRoleRoute;