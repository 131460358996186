import React from 'react';
/**
 * Note: Don't import/export all Views directly, use lazy loading!
 */
import { withSuspense } from '../components';
import NotFound from './NotFound';
import NotImplementedView from './NotImplemented';

/**
 * Views/Pages with Lazy Loading
 */
const Welcome = withSuspense(React.lazy(() => import('./Welcome')));
const About = withSuspense(React.lazy(() => import('./About')));
const DetailPatient = withSuspense(React.lazy(() => import('./DetailPatient'))); // Sample of non-implemented View m
const MyPatient = withSuspense(React.lazy(() => import('./MyPatient')));
const RecentlyCreatedPatient = withSuspense(React.lazy(() => import('./RecentlyCreatedPatient')));
const AdminManageAccount = withSuspense(React.lazy(() => import('./AdminManageAccount')));
const AdminManageHistory = withSuspense(React.lazy(() => import('./AdminManageHistory')));
const IndexAdminManageTool = withSuspense(React.lazy(() => import('./AdminManageTool')));
const ListPatientFinished = withSuspense(React.lazy(() => import('./ListPatientFinished')));
const User = withSuspense(React.lazy(() => import('./User')));
// const User = () => <NotImplementedView name="User" />; // Sample of non-implemented View

export {
    NotFound,
    About,
    Welcome,
    User,
    DetailPatient,
    MyPatient,
    RecentlyCreatedPatient,
    AdminManageAccount,
    AdminManageHistory,
    IndexAdminManageTool,
    ListPatientFinished
};
