import React, { createContext, useContext, useReducer } from 'react';
import { localStorageGet } from '../utils/localStorage';
import AppReducer from './AppReducer';

const initialAppState = {
  darkMode: false, // Overridden by useMediaQuery('(prefers-color-scheme: dark)') in AppStore
  error: '',
  isAuthenticated: false,
  currentUser: undefined,
  i18Lng: 'en',
  pageWellcome: 0,
  pageRecentlyCreatedPatient: 0,
  pageMyPatient: 0,
  pagePatientFinished: 0,
  rowsPerPage: 10,
  isCollapse: false,
};

/**
 * Instance of React Context for global AppStore
 *
 * import {AppContext} from './store'
 * ...
 * const [state, dispatch] = useContext(AppContext);
 *
 * OR
 *
 * import {useAppStore} from './store'
 * ...
 * const [state, dispatch] = useAppStore();
 *
 */
const AppContext = createContext(initialAppState);

/**
 * Main global Store as HOC with React Context API
 *
 * import AppStore from './store'
 * ...
 * <AppStore>
 *  <App/>
 * </AppStore>
 */
const AppStore = ({ children }) => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const previousDarkMode = Boolean(localStorageGet('darkMode'))

  const prefersCurrentUser = localStorageGet('me') ? localStorageGet('me') : undefined
  const rowsPerPage = localStorageGet('rowsPerPage') ? localStorageGet('rowsPerPage') : 10
  const isCollapse = localStorageGet('isCollapse') ? localStorageGet('isCollapse') : false
  const i18Lng = localStorageGet('i18nextLng') ? localStorageGet('i18nextLng') : 'vi'
  const initialState = {
    ...initialAppState,
    darkMode: previousDarkMode,
    currentUser: prefersCurrentUser,
    i18Lng: i18Lng,
    rowsPerPage,
    isCollapse
    // || prefersDarkMode,
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);
  return <AppContext.Provider value={[state, dispatch]}>{children}</AppContext.Provider>;
};

/**
 * Hook to use the AppStore in functional components
 */
const useAppStore = () => useContext(AppContext);

/**
 * HOC to inject the ApStore to functional or class component
 */
const withAppStore = (Component) => (props) => {
  return <Component {...props} store={useAppStore()} />;
};

export { AppStore as default, AppStore, AppContext, useAppStore, withAppStore };

