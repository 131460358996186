export const ROLES = {
  admin: 'admin',
  doctor: 'doctor',
  developer: 'developer',
}

export const checkRole = (arrUserRole, arrRoleIsAccesed) => {
  if (!arrUserRole) return false
  if (!arrRoleIsAccesed || !arrUserRole) return true
  for (let i = 0; i < arrUserRole.length; i++) {
    if (arrRoleIsAccesed.includes(arrUserRole[i].name)) {
      return true
    }
  }
  return false
}